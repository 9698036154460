$(function () {
    // Scroll back to top of grid if pager is clicked
    $(document).on('click', 'button.mixitup-control', function () {
        $('html,body').animate({
            scrollTop: $("#MixItUpC86A36").offset().top - 100
        }, 'slow');
    });
});
// Mixitup container
var containerEl = document.querySelector('.mixitup-container');
// Target selector
var targetSelector = '.col';
// Active hash
var activeHash = '';
// Column count
var columnCounter = document.querySelector('.column-counter');
// Current limit
var currentLimit= -1;
// Column limit lookup
var columnLimitLookup = {
    '2':8,
    '3':12,
    '4':18,
	'5':28
}

// Get the initial limit which with to instantiate the mixer.
currentLimit = getPaginationLimit();

/**
 * Deserializes a hash segment (if present) into in an object.
 *
 * @return {object|null}
 */
function deserializeHash() {
    var hash    = window.location.hash.replace(/^#/g, '');
    var obj     = null;
    var props   = [];

    if (!hash) return obj;

    obj = {};
    props = hash.split('&');

    props.forEach(function(props) {
        var pair = props.split('=');
        var propName = pair[0];

        //obj[propName] = propName === 'page' ? parseInt(pair[1]) : pair[1].split(',');
		obj[propName] = pair[1].split(',');
    });

    return obj;
}

/**
 * Serializes a uiState object into a string.
 *
 * @param   {object}    uiState
 * @return  {string}
 */
function serializeUiState(uiState) {
    var output = '';

    for (var key in uiState) {
        var values = uiState[key];

        if (!values.length) continue;

        output += key + '=';
        output += values.join(',');
        output += '&';
    };

    output = output.replace(/&$/g, '');

    return output;
}

/**
 * Constructs a `uiState` object using the
 * `getFilterGroupSelectors()` API method.
 *
 * @return {object}
 */
function getUiState() {
	// NB: You will need to rename the following keys to match the names of
    // your project's filter groups – these should match those defined
    // in your HTML.

	var uiState = {};

	var filterControls = $('.mixitup-filter-controls [data-filter-group]');

	$(filterControls).each(function(i, obj) {
		uiState[$(obj).attr('data-filter-group')] = mixer.getFilterGroupSelectors($(obj).attr('data-filter-group')).map(getValueFromSelector)
	});

    // var page = mixer.getState().activePagination.page;
	//
    // if (page > 1) {
    //     uiState.page = page;
    // }

    return uiState;
}

/**
 * Updates the URL hash whenever the current UI state changes.
 *
 * @param   {mixitup.State} state
 * @return  {void}
 */
function setHash(state) {
    var selector = state.activeFilter.selector;

    // Construct an object representing the current state of each
    // filter group

    var uiState = getUiState();
    //var page = uiState.page || 1;

    // Create a URL hash string by serializing the uiState object

    var newHash = '#' + serializeUiState(uiState);

    //if (selector === targetSelector && window.location.href.indexOf('#') > -1 && page === 1) {
	if (selector === targetSelector && window.location.href.indexOf('#') > -1) {
        // Equivalent to filter "all", and a hash exists, remove the hash

        activeHash = '';

        history.replaceState(null, document.title, window.location.pathname);
	//} else if (newHash !== window.location.hash && selector !== targetSelector || page > 1) {
    } else if (newHash !== window.location.hash && selector !== targetSelector) {
        // Change the hash

        activeHash = newHash;

        history.replaceState(null, document.title, window.location.pathname + newHash);
    }
}

/**
 * Updates the mixer to a previous UI state.
 *
 * @param  {object|null}    uiState
 * @param  {boolean}        [animate]
 * @return {Promise}
 */
function syncMixerWithPreviousUiState(uiState, animate) {
	$.each(uiState, function( k, v ) {
		// Prepend '.' to each value
		for(var i=0;i<v.length;i++){
			v[i] = "." + v[i];
		}

		// Set selectors
		mixer.setFilterGroupSelectors(k, v);
	});

	$('.mixitup-filter-controls [data-filter-group] select').formSelect();

	// Active page
    //activePage = (uiState && uiState.page) ? uiState.page : 1;

    // Parse the filter groups (passing `false` will perform no animation)
    return mixer.parseFilterGroups(animate ? true : false);
}

/**
 * Converts a selector (e.g. '.green') into a simple value (e.g. 'green').
 *
 * @param   {string} selector
 * @return  {string}
 */
function getValueFromSelector(selector) {
    return selector.replace(/\./g, '');
}

/**
 * Converts a simple value (e.g. 'green') into a selector (e.g. '.green').
 *
 * @param   {string} selector
 * @return  {string}
 */
function getSelectorFromValue(value) {
    return '.' + value;
}

function getColumns() {
    var styles = window.getComputedStyle(columnCounter);

    return parseInt(styles.fontSize);
}

/**
 * @return {number}
 */
function getPaginationLimit() {
    var columns = getColumns();

    return columnLimitLookup[columns];
}

/**
 * Compares the new limit to the current limit, and if has changed,
 * triggers a .paginate() API call to update the mixer.
 *
 * @return {void}
 */
function handleResize() {
    var newLimit = getPaginationLimit();

    // If the limit has not changed since the last resize event, do nothing.

    if (newLimit === currentLimit) return;

    currentLimit = newLimit;

    mixer.paginate({
        limit: currentLimit
    }, false);

    // NB: We don't want to animate the limit change and create
    // unneeded jank on resize, so `false` is passed to ensure the
    // operation happens syncronously.
}

/**
 * A function for filtering the values of the mixitup command object
 * generated by calling the `parseFilterGroups()` method.
 *
 * @param  {object} command
 * @return {object}
 */
// function handleParseFilterGroups(command) {
//     if (activePage > 1) {
//         // If an activePage greater than 1 has been parsed
//         // from the URL, update the command with a pagination
//         // instruction
//
//         command.paginate = activePage;
//     }
//
//     return command;
// }



// Window resize event
window.addEventListener('resize', handleResize);
// Instantiate Mixitup
var mixer = mixitup(containerEl, {
    multifilter: {
        enable: true,
		logicBetweenGroups:'and'
    },
    selectors:{
            target:'[data-ref="mixitup-target"]'
    },
    pagination: {
        limit: currentLimit,
        maintainActivePage: false
    },
    animation: {
        duration:500,
        effects: 'fade translateZ(-100%)',
        reverseOut:true,
        nudge:false,
        easing:'ease-in-out',
    },
    callbacks: {
		onMixEnd: setHash // Call the setHash() method at the end of each operation
    }
});

// UI state from hash
var uiStateFromHash = deserializeHash();

// If a valid uiState object is present on page load, filter the mixer
if (uiStateFromHash) {
    syncMixerWithPreviousUiState(uiStateFromHash);
}

